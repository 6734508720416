// Получаем элементы
const modal = document.getElementById("modal");
const openModalBtn = document.getElementById("openModal");
const closeModalBtn = document.getElementById("closeModal");
const body = document.body;

// Открытие модального окна с плавным эффектом
openModalBtn.addEventListener("click", function (e) {
  e.preventDefault();
  modal.style.display = "flex"; // Показ модального окна
  setTimeout(() => {
    modal.classList.add("show");
    modal.classList.remove("hide");
  }, 10); // Небольшая задержка для плавного старта
  body.classList.add("no-scroll"); // Отключение прокрутки страницы
});

// Закрытие модального окна с плавным эффектом
closeModalBtn.addEventListener("click", function () {
  modal.classList.add("hide");
  modal.classList.remove("show");
  setTimeout(() => {
    modal.style.display = "none";
    body.classList.remove("no-scroll"); // Включение прокрутки страницы
  }, 500); // Время синхронизировано с длительностью анимации
});

// Закрытие окна при клике вне его
window.addEventListener("click", function (e) {
  if (e.target === modal) {
    modal.classList.add("hide");
    modal.classList.remove("show");
    setTimeout(() => {
      modal.style.display = "none";
      body.classList.remove("no-scroll"); // Включение прокрутки страницы
    }, 500);
  }
});
