/*(function ($) {
  $(".contact-form").submit(function (event) {
    event.preventDefault();

    // Сообщения формы
    let successSendText = "Сообщение успешно отправлено";
    let errorSendText = "Сообщение не отправлено. Попробуйте еще раз!";
    let requiredFieldsText = "Заполните поля с именем и телефоном";

    // Сохраняем в переменную класс с параграфом для вывода сообщений об отправке
    let message = $(this).find(".contact-form__message");

    let form = $("#" + $(this).attr("id"))[0];
    let fd = new FormData(form);
    $.ajax({
      url: "../send-message-to-telegram.php",
      type: "POST",
      data: fd,
      processData: false,
      contentType: false,
      beforeSend: () => {
        $(".preloader").addClass("preloader_active");
      },
      success: function success(res) {
        $(".preloader").removeClass("preloader_active");

        // Посмотреть на статус ответа, если ошибка
        // console.log(res);
        let respond = $.parseJSON(res);

        if (respond === "SUCCESS") {
          message.text(successSendText).css("color", "#fff");
          setTimeout(() => {
            message.text("");
          }, 4000);
        } else if (respond === "NOTVALID") {
          message.text(requiredFieldsText).css("color", "#d42121");
          setTimeout(() => {
            message.text("");
          }, 3000);
        } else {
          message.text(errorSendText).css("color", "#d42121");
          setTimeout(() => {
            message.text("");
          }, 4000);
        }
      },
    });
  });
})(jQuery);*/

(function ($) {
  $(".contact-form").submit(function (event) {
    event.preventDefault();

    let successSendText = "Сообщение успешно отправлено!";
    let errorSendText = "Сообщение не отправлено. Попробуйте еще раз!";
    let requiredFieldsText = "Заполните поля с именем и телефоном";

    let form = $("#" + $(this).attr("id"))[0];
    let fd = new FormData(form);

    $.ajax({
      url: "../send-message-to-telegram.php",
      type: "POST",
      data: fd,
      processData: false,
      contentType: false,
      beforeSend: () => {
        $(".preloader").addClass("preloader_active");
      },
      success: function (res) {
        $(".preloader").removeClass("preloader_active");

        let respond = $.parseJSON(res);

        if (respond === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "Успех!",
            text: successSendText,
            confirmButtonText: "OK",
          });
          $(".contact-form")[0].reset();
        } else if (respond === "NOTVALID") {
          Swal.fire({
            icon: "warning",
            title: "Ошибка!",
            text: requiredFieldsText,
            confirmButtonText: "Заполнить снова",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ошибка отправки!",
            text: errorSendText,
            confirmButtonText: "Попробовать еще раз",
          });
        }
      },
      error: function () {
        $(".preloader").removeClass("preloader_active");
        Swal.fire({
          icon: "error",
          title: "Ошибка сети!",
          text: "Проверьте соединение и попробуйте снова.",
          confirmButtonText: "OK",
        });
      },
    });
  });
})(jQuery);
