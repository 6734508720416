// Функция для добавления/удаления класса в зависимости от прокрутки
function handleScroll() {
  const header = document.querySelector("header");
  if (window.scrollY > 30) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
}

// Добавляем обработчик события прокрутки
window.addEventListener("scroll", handleScroll);
