// Массивы с изображениями для каждого кейса
const galleryItems = {
  1: [
    "img/gallery-wok-1.jpg",
    "img/gallery-wok-2.jpg",
    "img/gallery-wok-3.jpg",
    "img/gallery-wok-4.jpeg",
    "img/gallery-wok-5.jpeg",
    "img/gallery-wok-6.jpeg",
    "img/gallery-wok-7.jpg",
  ],
  // Суши WOK
  2: [
    "img/gallery-sk-1.jpg",
    "img/gallery-sk-2.jpg",
    "img/gallery-sk-3.jpg",
    "img/gallery-sk-4.jpg",
    "img/gallery-sk-5.jpg",
    "img/gallery-sk-6.jpg",
    "img/gallery-sk-7.jpg",
    "img/gallery-sk-8.jpg",
    "img/gallery-sk-9.jpg",
  ],
  // 3-комнатная квартира
  3: [
    "img/gallery-k-1.jpg",
    "img/gallery-k-2.jpg",
    "img/gallery-k-3.jpg",
    "img/gallery-k-4.jpg",
    "img/gallery-k-5.jpg",
    "img/gallery-k-6.jpg",
    "img/gallery-k-7.jpg",
    "img/gallery-k-8.jpg",
  ],
  // 2-комнатная квартира
  4: [
    "img/gallery-t-1.jpg",
    "img/gallery-t-2.jpg",
    "img/gallery-t-3.jpg",
    "img/gallery-t-4.jpg",
    "img/gallery-t-5.jpg",
    "img/gallery-t-6.jpg",
    "img/gallery-t-7.jpg",
    "img/gallery-t-8.jpg",
  ],
  // 1-комнатная квартира
  5: [
    "img/gallery-r-1.jpg",
    "img/gallery-r-2.jpg",
    "img/gallery-r-3.jpeg",
    "img/gallery-r-4.jpeg",
    "img/gallery-r-5.jpeg",
    "img/gallery-r-6.jpeg",
    "img/gallery-r-7.jpeg",
  ],
  // Дизайнерский ремонт
  6: [
    "img/gallery-ba-1.jpg",
    "img/gallery-ba-2.jpg",
    "img/gallery-ba-3.jpg",
    "img/gallery-ba-4.jpg",
    "img/gallery-ba-5.jpg",
    "img/gallery-ba-6.jpg",
  ],
  // 2-комнатная квартира в ЖК B'aires
  7: [
    "img/gallery-x-1.jpg",
    "img/gallery-x-2.jpg",
    "img/gallery-x-3.jpg",
    "img/gallery-x-4.jpg",
    "img/gallery-x-5.jpg",
    "img/gallery-x-6.jpg",
    "img/gallery-x-7.jpg",
    "img/gallery-x-8.jpg",
    "img/gallery-x-9.jpg",
    "img/gallery-x-10.jpg",
    "img/gallery-x-11.jpg",
    "img/gallery-x-12.jpg",
    "img/gallery-x-13.jpg",
    "img/gallery-x-14.jpg",
  ], // Коттедж в Новогорск Клаб
};

// Функция для инициализации и открытия слайдера
function openGallery(galleryId) {
  const images = galleryItems[galleryId];

  // Удаляем существующий контейнер слайдера, если он есть
  if (document.querySelectorAll("[data-fslightbox]").length > 0) {
    document.querySelectorAll("[data-fslightbox]").forEach((el) => el.remove());
  }

  // Создаем новый HTML элемент для слайдера с обновленным набором изображений
  images.forEach((image, index) => {
    const anchor = document.createElement("a");
    anchor.setAttribute("href", image);
    anchor.setAttribute("data-fslightbox", "gallery");
    document.body.appendChild(anchor);
  });

  // Обновляем слайдер
  refreshFsLightbox();

  // Открываем первое изображение автоматически
  document.querySelector(`[data-fslightbox="gallery"]`).click();
}

// Назначаем обработчик событий для каждой кнопки
document.querySelectorAll(".btn--views").forEach((button) => {
  button.addEventListener("click", function () {
    const galleryId = this.getAttribute("data-gallery");
    openGallery(galleryId);
  });
});
